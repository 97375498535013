import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useRouter } from 'next/router'
import { subDataState } from '@/util/states'
import MainLayout from '../layout';

export default function CreatorLayout({ children }) {
// AUTH RESTRICTION HERE FOR CREATORS ONLY
  const router = useRouter();
  const subData = useRecoilValue(subDataState)
  const loading = subData === "pending"

  useEffect(() => {
    if (subData.creator || loading) return;

    router.push('/');
  }, [subData]);

  if (!subData || !subData.creator) return null;

  return (
    <MainLayout>
      {children}
    </MainLayout>
  )
}
