export const phi = 1.618034
export const sqrtPhi = 1.618034

export const mobileBreakpoint = 800
export const contentHeight = 'calc(100vh - 157px)'
export const carouselAnimDuration = 0.3
export const formSubmitTimeout = 800

export const DRAFT = 'draft'
export const LIVE = 'live'
export const REJECTED = 'rejected'

export const ADMIN = 'admin'
export const CREATORS = 'creators'
export const ALL = 'all'

export const DISCOVERIES = 'Discoveries'
export const PURCHASES = 'Purchases'

export const ACTIVE_TAB_CLASS = 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'
export const INACTIVE_TAB_CLASS = 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'

// Post/Upload Types
export const PAID = 'paid'
export const FREE = 'free'
